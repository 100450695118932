import React from "react";
import { NavLink } from "react-router-dom";

function CardBody({ title, order, align, categoryLink, description }) {
  return (
    <>
      <div
        className={"card col-12 col-lg-5 my-auto order-lg-" + order}
        style={{ width: "100%", zIndex: "2" }}
      >
        <div className={"card-body p-1 p-lg-5 card-" + align}>
          <h2 className="card-title mb-0 mb-lg-3 text-center text-lg-left">
            {title}
          </h2>
          <div className=" text-center text-lg-left">
            <p style={{ color: "#742b2b" }}>
              {description}
            </p>
            <NavLink
              className="font-weight-bold"
              to={"/ved-kendra/" + categoryLink}
            >
              SHOP NOW >
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

function CardImage({ imgSrc }) {
  return (
    <div className="card-image col-12 col-lg-7">
      <img
        src={imgSrc}
        alt="locket"
        className="img-fluid"
        id="card-img"
        style={{ height: 450, objectFit: "cover", width: "100%" }}
      />
    </div>
  );
}
export default function CardVedKendra(props) {
  let { categoryLink, imgSrc, title, index, id, description } = props;
  let align, order;
  if (index % 2 === 0) {
    align = "left";
    order = "first";
  } else {
    align = "right";
    order = "last";
  }
  return (
    <>
      <div className="card-container row no-gutters align-items-center my-3 my-lg-0 mb-lg-5">
        <CardImage imgSrc={imgSrc} alt="category" />
        <CardBody
          align={align}
          order={order}
          categoryLink={categoryLink}
          title={title}
          id={id}
          description={description}
        />
      </div>
    </>
  );
}
