import React from "react";
// import product1 from "../../images/product1.jpeg";
// import product2 from "../../images/product2.jpeg";
// import product3 from "../../images/product3.jpeg";
// import product4 from "../../images/product4.jpeg";
// import product5 from "../../images/product5.jpeg";
// import product6 from "../../images/product6.jpeg";

export default function Product() {
  function Image({ title }) {
    return (
      <>
        <a href="/ved-kendra/ashtamangal" className="product-img-link">
          <div className="product-img"></div>
        </a>
      </>
    );
  }
  return (
    <div id="products-section">
      <div className="my-lg-4">
        <h3 className="ml-lg-4">Featured Products</h3>
        <div className="row mx-2 justify-content-around">
          <Image title="title" />
          <Image />
          <Image />
          <Image />
          <Image />
          <Image />
        </div>
      </div>
    </div>
  );
}
