import React from "react";
import axios from "axios";
import config from "../../../helperFuncs/config";
import baseUrl from "../../../helperFuncs/baseUrl";

const url = Object.values(baseUrl)[0].SERVER_URI;
// const client_uri = baseUrl.baseUrl.CLIENT_URI;

async function deleteAddress(aid, uid, isDefault) {
  if (!isDefault) {
    if (window.confirm("Are you sure you want to delete this address?")) {
      await axios
        .post(`${url}/addressinfo/deleteaddress`, { uid, aid }, config)
        .then((res) => {
          console.log("address deleted");
          // alert("Address deleted");
        });
    } else {
      console.log("address not deleted");
      // alert("Address was not deleted");
    }
    window.location.href = "/service";
  } else {
    alert("You cannot delete a default address!!");
    console.log("address default");
  }
}

function makeDefault(aid, uid) {
  axios
    .post(`${url}/addressinfo/newdefaultaddress`, { uid, aid }, config)
    .then((res) => {
      alert("Default address updated.");
      window.location.reload();
    })
    .catch((err) => {
      alert("Default address was not updated");
    });
}
export default function AddressCard({ addressObj, isDefault, aid }) {
  const {
    address,
    city,
    country,
    phone,
    pin,
    receiverName,
    state,
  } = addressObj;
  const { uid } = localStorage;
  return (
    <div className="h-100">
      <div className="border rounded h-100" style={{ borderWidth: 2 }}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div>
              {isDefault ? (
                <div>
                  <div className="py-1 pl-2">
                    <span className="text-muted mb-0">Default Address</span>
                  </div>
                  <hr className="m-0" />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="py-2 px-3">
              <div>
                <span className="font-weight-bold">{receiverName}</span>
              </div>
              <div style={{ lineHeight: 1.3 }}>
                <div>
                  <span>{address}</span>
                </div>
                <div>
                  <span>
                    {city}, {state}
                  </span>
                </div>
                <div>
                  <span>{country}</span>
                </div>
                <div>
                  <span>{pin}</span>
                </div>
                <div>
                  <span>{phone}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 py-2 px-3">
            <div
              className="d-flex justify-content-start"
              style={{ textDecoration: "underline" }}>
              <div className="mx-1"> | </div>
              <div
                onClick={() => deleteAddress(aid, uid, isDefault)}
                style={{ cursor: "pointer" }}>
                Remove
              </div>
              {isDefault ? (
                <div />
              ) : (
                <>
                  <div className="mx-1"> | </div>
                  <div
                    onClick={() => makeDefault(aid, uid, isDefault)}
                    style={{ cursor: "pointer" }}>
                    Make Default
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
