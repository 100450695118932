import React, { Component } from "react";
import axios from "axios";
import AddressCard from "./AddressCard";
import { NavLink } from "react-router-dom";
import config from "../../../helperFuncs/config";
import baseUrl from "../../../helperFuncs/baseUrl";

const url = Object.values(baseUrl)[0].SERVER_URI;

function NewAddress() {
  return (
    <div className="col-4 mb-3">
      <div className="rounded h-100" style={{ border: "2px dashed #dee2e6" }}>
        <NavLink to="/service/address/add-new-address">
          <div className="d-flex align-items-center h-100">
            <div className="text-center w-100">
              <div>
                <div style={{ fontSize: 60, color: "#999" }}>+</div>
                <div style={{ fontSize: 20, color: "#999" }}>Add Address</div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default class AddressContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      defaultAddress: "",
      aidArray: "",
    };
    this.getAddresses = this.getAddresses.bind(this);
    this.getDefaultAddress = this.getDefaultAddress.bind(this);
  }
  componentDidMount() {
    this.getDefaultAddress();
  }
  getDefaultAddress() {
    const { uid } = localStorage;
    axios
      .post(`${url}/addressinfo/readdefaultaddress`, { uid }, config)
      .then((res) => {
        this.setState({
          defaultAddress: res.data,
        });
        console.log("default address : ", res.data);
        localStorage.setItem("aid", res.data);
        this.getAddresses();
      });
  }
  getAddresses() {
    const { uid } = localStorage;
    const addressData = { uid };
    axios
      .post(`${url}/addressinfo/readaddress`, addressData, config)
      .then((res) => {
        const aidArray = Object.keys(res.data);
        const addressArray = Object.values(res.data);
        console.log("addressArray : ", addressArray);
        console.log("aidArray : ", aidArray);
        this.setState({
          addresses: addressArray,
          aidArray: aidArray,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  renderAddress() {
    const { addresses, defaultAddress, aidArray } = this.state;
    const renderedAddresses = addresses.map((addressObj, index) => {
      if (aidArray[index] !== defaultAddress) {
        return (
          <div className="col-4 mb-3" key={index}>
            <AddressCard
              addressObj={addressObj}
              isDefault={false}
              aid={aidArray[index]}
            />
          </div>
        );
      }
      return (
        <div className="col-4 mb-3" key={index}>
          <AddressCard
            addressObj={addressObj}
            isDefault={true}
            aid={aidArray[index]}
          />
        </div>
      );
    });
    return renderedAddresses;
  }
  render() {
    return (
      <div>
        <div className="row">
          <NewAddress />
          {this.renderAddress()}
        </div>
      </div>
    );
  }
}
