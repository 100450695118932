import React from "react";
import { NavLink } from "react-router-dom";

export default function ZeroItems() {
  return (
    <div>
      <div>
        <h5>
          Pay faster for all your shopping needs{" "}
          <strong>with Ved Mudra.</strong>
        </h5>
        <br />
      </div>
      <div>
        <h2>Your Cart is empty.</h2>
      </div>
      <div>
        <NavLink to="/ved-kendra">
          <span
            style={{
              color: "var(--text-main)",
              textDecoration: "underline",
            }}
          >
            Continue Shopping
          </span>
        </NavLink>
      </div>
    </div>
  );
}
