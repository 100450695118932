const configs = {
  development: {
    // not using proxy
    SERVER_URI: "http://localhost:5000",
    CLIENT_URI: "http://localhost:3000",
    // using proxy
    // SERVER_URI: ""
  },
  production: {
    SERVER_URI: "https://ved-world-app.web.app",
    CLIENT_URI: "https://ved-world-frontend.vercel.app/",
    // SERVER_URI: "https://enigmatic-mesa-30368.herokuapp.com"
  },
};

module.exports.baseUrl = configs[process.env.NODE_ENV];
