import axios from "axios";
import React, { Component } from "react";
import config from "../../../helperFuncs/config";
import { baseUrl } from "../../../helperFuncs/baseUrl";
// import { NavLink } from "react-router-dom";

const isEmpty = (data) => data.trim() === "";

export default class newAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      state: "",
      country: "",
      receiverName: "",
      pin: "",
      phone: "",
      addressType: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmit(e) {
    const url = baseUrl.SERVER_URI;
    e.preventDefault();
    const values = Object.values(this.state);
    let valid = true;
    values.forEach((value) => {
      if (isEmpty(value)) valid = false;
    });
    if (valid) {
      const { uid } = localStorage;
      const dataSend = { address: { ...this.state }, uid };
      console.log(dataSend);
      console.log(url);
      await axios
        .post(`${url}/addressinfo/writeaddress`, dataSend, config)
        .then((res) => {
          alert("Address added");
          console.log("address added");
        })
        .catch((err) => {
          console.log(err);
        });
      const client_uri = baseUrl.CLIENT_URI;
      window.location.href = client_uri + "/service/address";
    }
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  render() {
    return (
      <div>
        <div className="container">
          <div className="my-4">
            <div className="card card-outline-secondary">
              <div className="card-header">
                <h3 className="mb-0">Fill New Address details</h3>
              </div>
              <div className="card-body">
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="address"
                        required
                        type="text"
                        placeholder="Enter Address"
                        onChange={this.handleChange}
                        value={this.state.address}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="city"
                        required
                        type="text"
                        placeholder="Enter city name"
                        onChange={this.handleChange}
                        value={this.state.city}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="state"
                        required
                        type="text"
                        placeholder="Enter state name"
                        onChange={this.handleChange}
                        value={this.state.state}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="country"
                        required
                        type="text"
                        placeholder="Enter country name"
                        onChange={this.handleChange}
                        value={this.state.country}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="receiverName"
                        required
                        type="text"
                        placeholder="Enter receiver name"
                        onChange={this.handleChange}
                        value={this.state.receiverName}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="pin"
                        required
                        type="text"
                        placeholder="Enter pincode"
                        onChange={this.handleChange}
                        value={this.state.pin}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="phone"
                        required
                        type="text"
                        placeholder="Enter phone number"
                        onChange={this.handleChange}
                        value={this.state.phone}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control mb-3"
                        name="addressType"
                        required
                        type="text"
                        placeholder="Enter address type"
                        onChange={this.handleChange}
                        value={this.state.addressType}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-success btn-lg float-right"
                    type="submit">
                    Add Address
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
