import React from "react";

export default function Card({ title, cost, cashback, displayRazorpay }) {
  return (
    <>
      <div className="card h-100">
        <div className="card-header">
          <h3 className="mb-0 text-center">{title}</h3>
        </div>
        <div className="card-body">
          <ul className="list-unstyled text-center">
            <li>
              {cost === "Free" ? (
                <div />
              ) : (
                  <sup style={{ top: "-1.3em", fontSize: "1.6em" }}>
                    <span className="fa fa-rupee" />
                  </sup>
                )}
              <span style={{ fontSize: "4em", fontWeight: "300" }}>{cost}</span>
            </li>
            <li>{cost === "Free" ? <div> </div> : <span>3 months</span>}</li>
            <li className="mb-lg-3">
              <span
                style={{
                  fontSize: "2em",
                  fontWeight: "300",
                  lineHeight: 1,
                  marginBottom: "10px",
                }}
              >
                {cashback}% flat mudra cashback
              </span>
            </li>
            <li>
              <button className="btn btn-modal" style={{ bottom: 0 }} onClick={() => {
                if (cost === "400") displayRazorpay(400);
                if (cost === "600") displayRazorpay(600)
              }} >
                Choose Plan
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
