import axios from 'axios';
import React, { Component } from 'react'
import config from '../../../helperFuncs/config';
import ModalName from './UpdateName';
import ModalPhoneno from './UpdatePhone';
import ModalPassword from './UpdatePassword';
import { baseUrl } from "../../../helperFuncs/baseUrl"

let url;

const styles = {
    borderBottom: "2.3px solid #f6e1d5",
    marginLeft: "10px",
    color: "var(--text-main)",
};
const link = {
    color: "brown",
    textDecoration: "underline"
}

class accountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}
        }
    }
    componentDidMount() {
        url = baseUrl.SERVER_URI
        this.getInfo();
    }
    getInfo() {
        console.log("account info");
        const data = { uid: localStorage.uid };
        console.log(`${url}`);
        axios.post(`${url}/userinfo/getinfo`, data, config)
            .then(res => {
                const info = res.data;
                console.log(info)
                this.setState({
                    userInfo: { ...info }
                });
            })
            .catch(err => console.log(err))
    }
    updateName(name) {
        const data = {
            name,
            uid: localStorage.uid
        }
        axios.post(`${url}/userinfo/addname`, data, config)
            .then(res => alert("name updated"))
            .catch(err => console.error(err))
    }
    updatePhoneno(phoneNumber) {
        const data = { uid: localStorage.uid, phoneNumber };
        axios.post(`${url}/userinfo/addphonenumber`, data, config)
            .then(() => alert("Phone no updated !!"))
            .catch(err => console.error(err))
    }
    updatePassword(newPassword) {
        const data = { uid: localStorage.uid, newPassword };
        axios.post(`${url}/userinfo/updatepassword`, data, config)
            .then(() => alert("Password updated !!"))
            .catch(err => console.error(err))
    }
    render() {
        return (
            <>
                <ModalName handleSubmit={this.updateName} />
                <ModalPhoneno handleSubmit={this.updatePhoneno} />
                <ModalPassword handleSubmit={this.updatePassword} />
                <div>
                    <div className="container">
                        <div className="px-lg-4 pb-3">
                            <div>
                                <div className="text-left pt-2 py-lg-5">
                                    <div>
                                        <h2 className="mb-0">
                                            <span
                                                style={styles}
                                            >
                                                Your Account
                  </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            {this.state.userInfo !== undefined ? (
                                <>
                                    <div className="row">
                                        <div className="col-5"><h3>Name:</h3></div>
                                        <div className="col-5"><h3>{this.state.userInfo.name}</h3></div>
                                        <div className="col-2">
                                            <h5
                                                data-toggle="modal"
                                                data-target="#modalUpdateName" type="button" style={link}>Update</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5"><h3>Phone No:</h3></div>
                                        <div className="col-5"><h3>{this.state.userInfo.phone}</h3></div>
                                        <div className="col-2">
                                            <h5
                                                data-toggle="modal"
                                                data-target="#modalUpdatePhoneno" type="button" style={link}>Update</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <p
                                            data-toggle="modal"
                                            data-target="#modalUpdatePassword" type="button" style={link}>Update Password</p>
                                    </div>
                                </>
                            ) : (<p>Loading..</p>)}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default accountInfo
