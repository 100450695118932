import React, { Component } from "react";
import axios from "axios";
import config from "../../helperFuncs/config";
import { baseUrl } from "../../helperFuncs/baseUrl";

const deleteItem = async (pid) => {
  const { uid } = localStorage;
  await axios
    .post(`${baseUrl.SERVER_URI}/usercart/readitem`, { uid }, config)
    .then((res) => {
      const data = res.data;
      let deleteCID = null;
      for (const [cartId, productDetails] of Object.entries(data)) {
        const { pID } = productDetails;
        if (pid === pID) {
          deleteCID = cartId;
          console.log(cartId);
          break;
        }
      }
      if (deleteCID !== null) {
        axios
          .post(
            `${baseUrl.SERVER_URI}/usercart/deletecart`,
            { uid, cid: deleteCID },
            config
          )
          .then((res) => {
            if (res.data.message === "Successfully deleted") {
              alert("Successfully Deleted!!");
            } else {
              alert("Some error occured..");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  setTimeout(() => window.location.reload(), 3000);
};

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.getProductDetails = this.getProductDetails.bind(this);
    this.state = {
      imageSrc: "",
      title: "",
      desc: "",
      price: "",
      quantity: "",
    };
  }
  componentDidMount() {
    this.getProductDetails();
  }
  getProductDetails() {
    const { pID, quantity } = this.props;
    if (pID !== "") {
      axios
        .get(`${baseUrl.SERVER_URI}/vedkendra/product/${pID}`, config)
        .then((res) => {
          const { desc, image, salePrice, title } = res.data;
          this.setState({
            imageSrc: image,
            title,
            price: salePrice,
            desc,
            quantity,
          });
        });
    }
  }
  renderProductDetails() {
    const { imageSrc, title, desc, price, quantity } = this.state;
    const { pID } = this.props;
    if (imageSrc !== "") {
      return (
        <div>
          <div className="row border">
            {/* image */}
            <div className="col-lg-3 px-0">
              <div className="h-100 w-100">
                <img
                  src={imageSrc}
                  alt="product"
                  style={{
                    height: 200,
                    width: 300,
                    objectFit: "cover",
                    maxWidth: "100%",
                    minWidth: "100%",
                  }}
                />
              </div>
            </div>
            {/*details  */}
            <div className="col-lg-7">
              <div className="py-3 d-flex flex-column justify-content-between h-100">
                <div>
                  <div>
                    <h5>{title}</h5>
                  </div>
                  <div>
                    <p>{desc}</p>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-row mt-auto">
                    <div className="mr-2">Quantity: {quantity}</div>
                    <div
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => deleteItem(pID)}>
                      Delete item
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* price */}
            <div className="col-lg-2">
              <div className="h-100 pt-3">
                <div className="text-center w-100">
                  <p>
                    <span className="fa fa-rupee"></span>
                    {price}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    } else {
      return <p>Loading..</p>;
    }
  }
  render() {
    console.log(this.state);
    const RenderedProducts = this.renderProductDetails();
    return <div>{RenderedProducts}</div>;
  }
}
