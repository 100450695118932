import React from "react";
import { NavLink } from "react-router-dom";

function openNav() {
  document.getElementById("mySidebar").style.width = "250px";
  document.getElementById("mainSidebar").style.marginLeft = "250px";
}
function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  document.getElementById("mainSidebar").style.marginLeft = "0";
}

export default function SideBar() {
  return (
    <div className="text-left">
      <div id="mySidebar" className="sidebar">
        <button
          className="closebtn"
          onClick={closeNav}
          style={{ cursor: "pointer" }}
        >
          &times;
        </button>
        <NavLink exact to="/">
          Home
        </NavLink>
        <NavLink exact to="/todays-deals">
          Today's Deals
        </NavLink>
        <NavLink exact to="/services">
          Services
        </NavLink>
        <NavLink exact to="/contact">
          Contact
        </NavLink>
        <NavLink exact to="/cart">
          Cart
        </NavLink>
      </div>

      <div id="mainSidebar">
        <button className="openbtn" onClick={openNav}>
          &#9776;
        </button>
      </div>
    </div>
  );
}
