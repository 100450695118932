import React from "react";
import { NavLink } from "react-router-dom";
import AddressContainer from "../Services/address/AddressContainer";

export default function SelectAddress() {
  return (
    <div>
      <div className="container">
        <div className="p-lg-4 pb-3">
          <div className="mb-3">
            <h3>Please select default address</h3>
          </div>
          <div>
            <AddressContainer />
          </div>
          <div>
            <div className="d-flex justify-content-end">
              <NavLink to={"/payment/modeofpayment"}>
                <button className="btn btn-modal">Proceed to payment</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
