import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// BOOTSTRAP
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/font-awesome/css/font-awesome.min.css";
// COMPONENTS
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
// PAGES
import home from "./components/Home/home";
import about from "./components/About/about";
import privacyPolicy from "./components/About/privacyPolicy";
import shippingPolicy from "./components/About/shippingPolicy";
import todaysDeals from "./components/todaysDeals";
import contact from "./components/Contact/contact";
import vedVastu from "./components/vedVastu";
import vedMudra from "./components/VedMudra/vedMudra";
import vedChakra from "./components/vedChakra";
import cart from "./components/Cart/cart";
import order from "./components/Orders/order";
// SERVICES
import service from "./components/Services/service";
import address from "./components/Services/address/address";
import accountInfo from "./components/Services/accountInfo/accountInfo";
import newAddress from "./components/Services/address/newAddress";
import Cancellation from "./components/Services/Cancellation";
// VED KENDRA
import vedKendra from "./components/VedKendra/vedKendra";
import Category from "./components/VedKendra/Category";
import Product from "./components/VedKendra/Product";
// PAYMENT
import SelectAddress from "./components/Payment/SelectAddress";
import ModeOfPayment from "./components/Payment/OrderSummary";
// import PaymentCOD from "./components/Payment/PaymentCOD";
import PaymentRazorpay from "./components/Payment/PaymentRazorpay";
// CHATBOT
import Chatbot from "./components/Common/Chatbot";
import termsConditons from "./components/About/termsConditons";


const CategoryWithLink = ({ match }) => {
  return <Category categoryLink={match.params.categoryLink} match={match} />;
};

const ProductWithLink = ({ match }) => {
  return (
    <Product
      categoryLink={match.params.categoryLink}
      productLink={match.params.productLink}
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {} };
  }
  render() {
    return (
      <>
        <BrowserRouter>
          <Navbar />
          <Switch>

            <Route exact path="/chatbot" component={Chatbot} />

            <Route exact path="/" component={home} />
            <Route exact path="/todays-deals" component={todaysDeals} />
            <Route exact path="/contact" component={contact} />
            <Route exact path="/service" component={service} />
            <Route exact path="/about" component={about} />
            <Route exact path="/privacy-policy" component={privacyPolicy} />
            <Route exact path="/terms-and-conditions" component={termsConditons} />
            <Route exact path="/shipping-policy" component={shippingPolicy} />
            <Route exact path="/ved-kendra" component={vedKendra} />
            <Route exact path="/ved-chakra" component={vedChakra} />
            <Route exact path="/ved-mudra" component={vedMudra} />
            <Route exact path="/ved-vastu" component={vedVastu} />
            <Route exact path="/yourorders" component={order} />
            <Route
              exact
              path="/ved-kendra/:categoryLink"
              component={CategoryWithLink}
            />
            <Route
              exact
              path="/ved-kendra/:categoryLink/:productLink"
              component={ProductWithLink}
            />
            <Route
              exact
              path="/service/address/add-new-address"
              component={newAddress}
            />
            <Route exact path="/service/address" component={address} />
            <Route exact path="/service/cancellation" component={Cancellation} />
            <Route exact path="/service/accountInfo" component={accountInfo} />
            <Route exact path="/cart" component={cart} />
            {/* payment */}
            <Route
              exact
              path="/payment/selectaddress"
              component={SelectAddress}
            />
            <Route
              exact
              path="/payment/modeofpayment"
              component={ModeOfPayment}
            />
            <Route
              exact
              path="/payment/modeofpayment/cod"
              // component={PaymentCOD}
              component={PaymentRazorpay}
            />
            <Route
              exact
              path="/payment/modeofpayment/razorpay"
              component={PaymentRazorpay}
            />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </BrowserRouter>
      </>
    );
  }
}
export default App;
