import React from "react";
import { NavLink } from "react-router-dom";
import Card from "./Card";

export default function service() {
  return (
    <div id="services">
      <div className="container p-0 ">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                Customer Services
              </span>
            </h2>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-4">
            <NavLink to="/service/address">
              <Card
                icon="fa fa-home"
                title="Manage Address"
                text1="Update your address"
                text2="Add or edit addresses"
              />
            </NavLink>
          </div>
          <div className="col-lg-4">
            <NavLink to="/service/accountinfo">
              <Card
                icon="fa fa-user"
                title="Account Settings"
                text1="Change email, password"
                text2="Update login information"
              />
            </NavLink>
          </div>
          <div className="col-lg-4">
            <NavLink to="/service/cancellation">
            <Card
              icon="fa fa-undo"
              title="Returns & Refunds"
              text1="Return or replace items"
              text2="Print return mailing labels"
            />
            </NavLink>
          </div>
          <div className="col-lg-4">
            <a href="mailto:vedworld@email.com">
              <Card
                icon="fa fa-users"
                title="Complains"
                text1="Help & Support"
                text2=" "
              />
            </a>
          </div>
          <div className="col-lg-4">
            <NavLink to="/yourorders">
              <Card
                icon="fa fa-archive"
                title="Your Orders"
                text1="Track packages"
                text2="Edit or cancel orders"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
