import React from "react";
import Card from "./Card";
import about_ashwyn from "../../images/about_ashwyn.jpeg";
import about_vedworld from "../../images/about_vedworld.png";
import about_punit from "../../images/about_punit.jpeg";

export default function about() {
  return (
    <div id="about-section">
      <div className="container p-0">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                About Us
              </span>
            </h2>
          </div>
        </div>
        <Card
          order="first"
          title="Ved World"
          content="VEDAS are the most primordial source of knowledge and wisdom throughout the universe.
It was this thought that emerged as an idea to create the world’s first Spiritual Science
Company : VED WORLD,
Our motto is to spread this sacred ancient knowledge of the Vedas to the whole world for
the benefit of the entire mankind via our Patented Products, Processes, & Services, We as a
spiritual science company are engaged in R&D to ensure our faithful customers that our
products are completely unique & differ in their quality, process of creation & manifested
for growth and progress.
VED WORLD gives you the satisfaction of becoming a part of the great Vedic revolution
which brings happiness and peace."
          imgSrc={about_vedworld}
        />
        <Card
          order="last"
          title="Founder"
          content="Dr Punit Bhardwaj having graduated in Biotech engineering, punit started
his career as a junior scientist in TIFR Colaba. Dr Punit is a Vedic Pundit ,
member of AFA - AMERICAN FEDERATION OF ASTROLOGERS , BAVA -
BRITISH ASSOCIATION OF VEDIC ASTROLOGY, and also an eminent
spiritual writer for various reputed magazines and journals. He also dons
the hat of principal of JYOTISH VIDYAPEETH DEEMED UNIVERSITY. Having
a PH.D in Astrology gives him the knowledge to be a Healer and life coach.
He’s associated with the indian express group for several years and has also
been awarded the RAJASTHAN RATNA AWARD 2012, He had the vision to
spread the power of vedic sciences for the upliftment of society and the
progress of All."
          imgSrc={about_punit}
        />
        <Card
          order="first"
          title="Co-Founder"
          content="Dr Ashwyn Nayak having graduated in Computer Engineering is a bridge
between technology and spirituality . Awarded a honorary doctorate in
spiritual science, Ashwyn makes sure that the Quality & certifications which
Ved World stands for is implemented on each and every product through
quality and cosmic energy testing tools & technologies. His knowledge about
metallurgy and cosmic energies adds new dimensions to the existing panel
of experts that Ved World attracts.
Dr. Ashwyn Nayak is also a certified Filmmaker & Technologist, having
worked with various brands, productions & serial entrepreneurship on
several high profile Brands & companies."
          imgSrc={about_ashwyn}
        />
      </div>
    </div>
  );
}
