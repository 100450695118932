import React from "react";
import CardWelcome from "./CardWelcome";
import CardWelcomeCommon from "./CardWelcomeCommon";
import welcome3 from "../../images/home/welcome3.jpg";
import welcome4 from "../../images/home/welcome4.jpg";
import welcome5 from "../../images/home/welcome5.jpg";
import welcome6 from "../../images/home/welcome6.jpg";
import welcome7 from "../../images/home/welcome7.jpg";

export default function welcome({ name }) {
  if (name === "") return <div />
  return (
    <>
      <div className="m-lg-5 p-lg-4">
        <div className="row no-gutters">
          <div className="col-12 col-lg-3 mb-4 mb-lg-0">
            <CardWelcome name={name} />
          </div>
          <div className="col-12 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-center">
            <CardWelcomeCommon
              imgSrc={[welcome3, welcome5, welcome6, welcome7]}
              title="Popular Products"
            />
          </div>
          <div className="col-12 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-center">
            <CardWelcomeCommon
              imgSrc={[welcome6, welcome7, welcome4, welcome3]}
              title="Hot Deals"
            />
          </div>
          <div className="col-12 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-center ">
            <CardWelcomeCommon
              imgSrc={[welcome4, welcome3, welcome5, welcome7]}
              title="What's new?"
            />
          </div>
        </div>
      </div>
    </>
  );
}
