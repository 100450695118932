import React from "react";
import logo from "../../images/logo.png";
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import ModalTab from "./ModalTab";
import ModalSeller from "./ModalSeller";
import Search from "./Search";

const logoutFunction = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  localStorage.removeItem("aid");
  window.location.reload();
};

export default function Navbar() {
  return (
    <div id="nav-section">
      <ModalSeller />
      {/* LOGIN MODAL */}
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalTab title="Login" />
          </div>
        </div>
      </div>
      {/* SIGNUP MODAL */}
      <div
        className="modal fade"
        id="signupModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalTab title="Sign up" />
          </div>
        </div>
      </div>

      {/* PRIMARY NAVBAR */}
      <nav className="navbar navbar-expand-lg navbar-light justify-content-around nav-primary p-0 pt-1 py-lg-3">
        {/* LOGO */}
        <div className="col-6 col-lg-4 text-center order-1">
          <div className="d-flex jutify-content-center align-items-center">
            <SideBar />
            <NavLink to="/">
              <img src={logo} alt="logo" className="img-fluid" width="100" />
            </NavLink>
          </div>
        </div>
        {/* SEARCH BAR */}
        <div className="col-12 col-lg-4 my-2 order-3 order-md-2">
          <div style={{ width: "100%" }}>
            <Search />
          </div>
        </div>
        {/* ICONS */}
        <div className="col-6 col-lg-4 order-2 order-md-3">
          <div>
            <ul className="navbar-nav d-flex">
              <li className="nav-item font-weight-bold d-flex align-items-center justify-content-center mr-2 ml-auto">
                {localStorage.token === undefined ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-modal nav-signin"
                      data-toggle="modal"
                      data-target="#loginModal"
                    >
                      Login
                    </button>
                    <span style={{ color: "var(--accent-dark)" }}> /</span>
                    <button
                      type="button"
                      className="btn btn-modal nav-signin"
                      data-toggle="modal"
                      data-target="#signupModal"
                    >
                      Sign Up
                    </button>
                  </>
                ) : (
                    <button
                      type="button"
                      className="btn btn-modal nav-signin"
                      onClick={logoutFunction}
                    >
                      Logout
                    </button>
                  )}
              </li>
              <li className="nav-item font-weight-bold d-none d-lg-flex align-items-center justify-content-center mx-2">
                <NavLink to="/yourorders">
                  <button
                    className="nav-link"
                    style={{
                      outline: "none",
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                      color: "var(--accent-dark)",
                    }}
                  >
                    <span className="fa fa-shopping-bag"></span> Your Orders
                </button>
                </NavLink>
              </li>
              <li className="nav-item font-weight-bold d-none d-lg-flex align-items-center justify-content-center mx-2">
                <NavLink
                  className="nav-link"
                  style={{
                    outline: "none",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "var(--accent-dark)",
                  }}
                  to="/cart"
                >
                  <span className="fa fa-shopping-cart"></span> Cart
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* SECONDARY NAVBAR */}
      <nav className="navbar navbar-expand-lg navbar-light nav-secondary d-none d-lg-flex">
        <div className="collapse navbar-collapse" id="Navbar">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item d-flex align-items-center">
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink exact to="/ved-kendra/ashtamangal">
                Shop
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink exact to="/about">
                About
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink exact to="/service">
                Help & Support
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
          <li className="d-flex align-items-center">
            <button className="btn btn-outline-dark"
              type="button"
              data-toggle="modal"
              data-target="#modalSeller">Become A Seller !</button>
          </li>
        </div>
      </nav>
    </div>
  );
}
