import React from 'react'

const ModalSeller = () => {
    return (
        <div
            className="modal fade"
            id="modalSeller"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Seller Details Form</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action="mailto:sanket@email.com" method="post" encType="text/plain" target="_blank" >
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email address"
                                    name="email"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    name="phoneno"
                                />
                            </div>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="typeOfUser" id="seller" value="seller" />
                                    <label className="form-check-label" htmlFor="seller">
                                        Seller
  </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="typeOfUser" id="admin" value="admin" />
                                    <label className="form-check-label" htmlFor="admin">
                                        Admin
  </label>
                                </div>
                                <div className="form-check disabled">
                                    <input className="form-check-input" type="radio" name="typeOfUser" id="celeb" value="celeb" />
                                    <label className="form-check-label" htmlFor="celeb">
                                        Celebrity
  </label>
                                </div>
                            </div>
                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-2"
                                    data-dismiss="modal"
                                >
                                    Close
      </button>
                                <input
                                    type="submit"
                                    className="btn btn-modal"
                                    value="Submit"
                                />
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ModalSeller
