import React from "react";

export default function privacyPolicy() {
  return (
    <div id="about-section">
      <div className="container p-0">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                Shipping Policy
              </span>
            </h2>
          </div>
        </div>
        <div style={{marginBottom:"50px"}}>
                During opening hours will usually be able to
                handle and package your order immediately. We try to deliver within 7 days in India; longer when shipped
                to a different country (1-21 days).
                <br/><br/>

                If a delivery cannot be made because you are not at home, the delivery service will leave you a
                notification. In that case, the notification will indicate if your order will be delivered again at a
                later date, and has been delivered to one of your neighbours.
                <br/><br/>

                <strong>Shipment costs</strong><br/>
                Within India we have FREE SHIPPING, regardless of how many items have been ordered. Shipping costs to
                countries other than India can be calculated on the My-shopping-bag page once you have placed one or
                more items in your cart.<br/><br/>

                <strong>Delivery time</strong><br/>
                Within India, we aim to get your order delivered between 1-7 working days. Within the rest of the
                countries, orders will be delivered between 1-21 working days.
                <br/><br/>

                <strong>Track & Trace and shipping confirmation</strong><br/>
                When your order has been shipped, you will receive a confirmation of shipment from us at the email
                address you have provided, including a track-and-trace code. With this code, you can easily and quickly
                track the status of your order on the website of the delivery service.
        </div>
        </div>
        </div>
  );
}
