import ComingSoon from "./ComingSoon";
import React from "react";

export default function vedVastu() {
  return (
    <>
      <ComingSoon />
    </>
  );
}
