import React from "react";
import DisplayCart from "./DisplayCart";

export default function cart() {
  return (
    <div>
      <DisplayCart />
    </div>
  );
}
