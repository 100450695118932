import Axios from "axios";
import React, { Component } from "react";
import config from "../../helperFuncs/config";
import Address from "./Address";
import Cart from "./Cart";
import PaymentMethod from "./PaymentMethod";
import {baseUrl} from "../../helperFuncs/baseUrl";

const { uid } = localStorage;

export default class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressInfo: {},
      cartInfo: {},
      amount: "",
    };
    this.getDetails.bind(this);
  }
  componentDidMount() {
    this.getDefaultAid();
  }
  getDefaultAid() {
    const data = { uid };
    let aid;
    Axios.post(`${baseUrl.SERVER_URI}/addressinfo/readdefaultaddress`, data, config)
      .then((res) => {
        console.log(res.data);
        aid = res.data;
        this.getDetails(aid);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getDetails(aid) {
    const data = { uid, aid };
    Axios.post(`${baseUrl.SERVER_URI}/order/buynow`, data, config)
      .then((res) => {
        console.log(res.data);
        this.setState({
          addressInfo: res.data.address,
          cartInfo: res.data.cartDetails,
          amount: String(res.data.amountPaid),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  renderAddress() {
    const { addressInfo } = this.state;
    if (Object.values(addressInfo).length !== 0) {
      return (
        <>
          <h6>Address</h6>
          <Address addressInfo={this.state.addressInfo} />
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
  renderCart() {
    const { cartInfo, amount } = this.state;
    if (Object.values(cartInfo).length !== 0) {
      const cartValues = Object.values(cartInfo);
      cartValues.pop();
      const renderedCart = cartValues.map((cartItem, index) => {
        return <Cart cartInfo={cartItem} key={cartItem + index} />;
      });
      return (
        <>
          <h6>Cart Items</h6>
          {renderedCart}
          <p>Subtotal: {amount} </p>
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
  render() {
    console.log(this.state);
    const RenderedAddress = this.renderAddress();
    const RenderedCart = this.renderCart();
    return (
      <div>
        <div className="container">
          <div className="p-lg-4 pb-3">
            <div>
              <div>
                <h3>Order Summary</h3>
              </div>
              <div className="my-4">{RenderedAddress}</div>
              <div className="my-4">{RenderedCart}</div>
              <PaymentMethod />

              <div>
                {/* <button className="btn btn-modal" onClick={payment}> */}
                {/* Continue to Payment
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
