import React from 'react'
import { Widget } from 'rasa-webchat';

function Chatbot() {
    return (
        <Widget
            initPayload={"/get_started"}
            socketUrl={"https://ved-bot.herokuapp.com/"}
            socketPath={"/socket.io/"}
            customData={{ "language": "en" }} // arbitrary custom data. Stay minimal as this will be added to the socket
            title={"Title"}
        />
    )
}

export default Chatbot
