import Axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../helperFuncs/baseUrl';
import { toKebabCase } from '../../helperFuncs/toKebabCase';

const url = baseUrl.SERVER_URI;

const styles = {
    position: "absolute",
    zIndex: 2,
    background: "white"
}

function RenderResult({ name, type, resetState }) {
    let uri;
    if (type === "product") {
        const prodUri = toKebabCase(name);
        uri = "/ved-kendra/ashtamangal/" + prodUri;
    } else {
        const categoryUri = toKebabCase(name);
        uri = "/ved-kendra/" + categoryUri;
    }
    console.log(uri);
    return (
        <div>
            <Link to={uri} onClick={resetState}>
                <h5 style={{ color: "#000" }}>{name}</h5>
                <small className="text-muted">in {type}</small>
            </Link>
        </div>
    )
}

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            searchedResult: []
        };
        this.onChange = this.onChange.bind(this);
        this.resetState = this.resetState.bind(this)
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.getSearchResults();
    }
    getSearchResults() {
        Axios.post(`${url}/search/search`, { keyword: this.state.keyword })
            .then(res => {
                this.setState({
                    searchedResult: [...res.data.searchResult]
                })
            })
    }
    resetState() {
        this.setState({ keyword: "", searchResult: [] })
    }
    render() {
        console.log(this.state);
        return (
            <>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text fa fa-search d-flex align-items-center round-search border-0"></span>
                    </div>
                    <input
                        type="text"
                        className="form-control round-input border-0"
                        placeholder="Search here"
                        name="keyword"
                        value={this.state.keyword}
                        onChange={this.onChange}
                    />
                </div>
                {this.state.keyword !== "" ? (
                    <>
                        <div style={styles}>
                            {this.state.searchedResult.map((result, key) => (
                                <RenderResult name={result.name} resetState={this.resetState} type={result.type} />
                            ))}
                        </div>
                    </>
                ) : (<div />)}
            </>
        )
    }
}
