import React from "react";

export default function ComingSoon() {
  return (
    <div className="comingSoon">
      <div>
        <h1>Coming Soon...</h1>
      </div>
    </div>
  );
}
