import React from "react";

export default function Card({ icon, text }) {
  return (
    <>
      <div className="text-center">
        <span className={icon + " fa-3x"} style={{ color: "var(--accent)" }} />
      </div>
      <div className="text-center">
        <p
          className="mb-0"
          style={{ color: "var(--text-main)", fontSize: "12px" }}
        >
          {text}
        </p>
      </div>
    </>
  );
}
