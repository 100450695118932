import React from "react";
import AddressContainer from "./AddressContainer";

const styles = {
  borderBottom: "2.3px solid #f6e1d5",
  marginLeft: "10px",
  color: "var(--text-main)",
};

export default function address() {
  return (
    <div>
      <div className="container">
        <div className="px-lg-4 pb-3">
          <div>
            <div className="text-left pt-2 py-lg-5">
              <div>
                <h2 className="mb-0">
                  <span
                    style={styles}
                  >
                    Your Addresses
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <AddressContainer />
        </div>
      </div>
    </div>
  );
}
