import React, { Component } from "react";
// COMPONENTS
import Slider from "./Slider";
import CardSection from "./CardSection";
import Welcome from "./Welcome";
import Product from "./Product";
import Axios from "axios";
import { baseUrl } from "../../helperFuncs/baseUrl";
import config from "../../helperFuncs/config";

const uid = localStorage.uid;

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    }
  }
  componentDidMount() {
    this.checkVerified();
  }
  checkVerified() {
    if (uid) {
      Axios.post(`${baseUrl.SERVER_URI}/userinfo/getinfo`, { uid }, config)
        .then(res => {
          this.setState({
            name: res.data.name
          })
        })
        .catch(err => console.error(err))
    }
  }
  render() {
    return (
      <>
        <Slider />
        <Welcome name={this.state.name} />
        <CardSection />
        <Product />
      </>
    );
  }
}