import React from "react";

export default function Address({ addressInfo }) {
  const {
    address,
    addressType,
    city,
    country,
    phone,
    pin,
    receiverName,
    state,
  } = addressInfo;
  return (
    <div>
      <div className="card p-3">
        <div>{receiverName} - </div>
        <div>
          {address}, {addressType}, {city}, {state}, {country} - {pin}
        </div>
        <div>{phone}</div>
      </div>
    </div>
  );
}
