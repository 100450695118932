import React from "react";
import vedMudraLogo from "../../images/vedMudra.png";
import Card from "./Card";
import axios from "axios";
import { baseUrl } from "../../helperFuncs/baseUrl";
import config from "../../helperFuncs/config";

const url = baseUrl.SERVER_URI;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

async function displayRazorpay(pay) {
  // wait for razorpay sdk to load
  const result = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!result) {
    alert("Razorpay SDK failed to load!!");
    return;
  }

  const data = { pay };
  axios.post(`${url}/vedmudra/vedmudrapayment`, data, config)
    .then(res => {
      const {
        order,
        // error
      } = res.data.order;

      var options = {
        key: "rzp_test_ZZhr7lNi2UkBQm",
        name: "Payment for Ved World Subscription",
        description: "Test Transaction",
        image: "/favicon-32x32.png",
        order_id: order,
        amount: (pay * 100),
        handler: function (response) {
          console.log(response)
          alert("order completed with payment id: " + response.razorpay_payment_id)
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    })
}


export default function vedMudra() {
  console.log("check payment");
  return (
    <div id="vedMudra">
      <div className="container p-0">
        <div>
          <div className="text-left pt-2 py-lg-5 text-lg-center">
            <div>
              <h2 className="font-weight-bold mb-0">
                <img src={vedMudraLogo} height="50" alt="ved mudra" />
                <span
                  style={{
                    borderBottom: "2.3px solid #f6e1d5",
                    marginLeft: "10px",
                  }}
                >
                  Ved Mudra
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row mb-lg-5">
          <div className="col-lg-4">
            <div className="p-lg-4 h-100">
              <Card title="Bronze" cost="Free" cashback="3" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="p-lg-4">
              <Card title="Silver" cost="400" cashback="5" displayRazorpay={displayRazorpay} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="p-lg-4">
              <Card title="Gold" cost="600" cashback="10" displayRazorpay={displayRazorpay} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
