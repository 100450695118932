import React from "react";

export default function Card({ icon, text1, text2, title }) {
  return (
    <>
      <div className="mb-4">
        <div className="border" style={{ height: "100px" }}>
          <div className="p-2">
            <div className="row">
              <div className="col-4 pr-0">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <i
                    className={icon}
                    style={{ fontSize: "4.5em", color: "var(--accent)" }}
                  />
                </div>
              </div>
              <div className="col-8">
                <div>
                  <h5 style={{ color: "var(--text-main)" }}>{title}</h5>
                </div>
                <div className="text-muted">{text1}</div>
                <div className="text-muted">{text2}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
