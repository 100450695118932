import React from "react";
import Card from "./Card";
// icons
// map-marker-alt
// phone-alt
// envelope

export default function contact() {
  return (
    <div id="contact-section">
      <div className="container p-0 ">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                Contact Us
              </span>
            </h2>
          </div>
        </div>
        <div className="mb-lg-5">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-12">
                  <form action="mailto:boardofdirectors@vedworld.org" method="POST"
                    enctype="text/plain"
                    name="EmailForm">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="contactus-name"
                        aria-describedby="emailHelp"
                        placeholder="Your Name"
                        name="Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="contactus-email"
                        aria-describedby="emailHelp"
                        placeholder="Your email"
                        name="Email"
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="contactus-message"
                        rows="3"
                        placeholder="Your message"
                        style={{ resize: "none" }}
                        name="Message"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-modal">
                      Submit
                    </button>
                  </form>
                </div>
                <div className="col-lg-5">
                  <div className="row flex-column h-100">
                    <div className="col mt-lg-2">
                      <Card
                        icon="fa fa-map-marker"
                        text="FLAT NO 101 WING B, GREEN WOODS CO-OP HSG SOC.LTD PLT NO 518, CHIKWOODI, SHIMPOLI LINK ROAD, BORIVALI WEST Mumbai City MH 400092 IN"
                      />
                    </div>
                    <div className="col my-lg-1">
                      <Card icon="fa fa-phone" text="092212 86774" />
                    </div>
                    <div className="col mb-lg-2">
                      <Card
                        icon="fa fa-envelope"
                        text="boardofdirectors@vedworld.org"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
