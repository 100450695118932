import React from "react";

export default function Card({ order, title, content, imgSrc }) {
  return (
    <div>
      <div className="card mb-5" style={{ maxWidth: "100%" }}>
        <div className="row no-gutters">
          <div className={"col-md-5 order-lg-" + order}>
            <div className="d-flex align-items-center h-100">
              {title === "Ved World" ? (
                <img
                  src={imgSrc}
                  className="card-img"
                  alt="..."
                  style={{ maxWidth: "550px" }}
                />
              ) : (
                <img
                  src={imgSrc}
                  className="card-img h-100"
                  alt="..."
                  style={{ maxWidth: "550px", objectFit: "cover" }}
                />
              )}
            </div>
          </div>
          <div className="col-md-7 p-lg-3 d-flex align-items-center">
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
