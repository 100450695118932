import React from "react";

export default function Card(props) {
  return (
    <div className="home-card h-100">
      <div className="card p-4 p-lg-0 h-100" style={{ width: "100%" }}>
        <div className="px-md-5 pt-md-5">
          <div className="px-md-3">
            <img
              src={props.imgSrc}
              className="card-img-top img-fluid"
              alt="ved service"
            />
          </div>
        </div>
        <div className="card-body p-0 p-lg-3">
          <h5 className="card-title text-center my-md-3">{props.name}</h5>
          <p className="card-text text-center px-md-3 my-md-3 d-none d-lg-block">
            {props.content}
          </p>
        </div>
      </div>
    </div>
  );
}
