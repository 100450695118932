import React, { Component } from "react";
import CardVedKendra from "./CardVedKendra";
import axios from "axios";
// IMAGES
import vedKendraLogo from "../../images/vedKendra.png";
// HELPER FUNCTIONS
import { toKebabCase } from "../../helperFuncs/toKebabCase";
import { baseUrl } from "../../helperFuncs/baseUrl";

export default class vedKendra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
    this.getCategories = this.getCategories.bind(this);
  }
  componentDidMount() {
    this.getCategories();
  }
  getCategories() {
    axios
      .get(`${baseUrl.SERVER_URI}/vedkendra/category`)
      // .get("/vedkendra/category")
      .then((res) => {
        console.log(res.data);
        this.setState({
          categories: Object.values(res.data),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  renderCategories() {
    if (this.state.categories.length) {
      const { categories } = this.state;
      const renderedCategories = categories.map((category, index) => {
        const { coverImage, id, name, desc } = category;
        const linkName = toKebabCase(name);
        return (
          <CardVedKendra
            categoryLink={linkName}
            imgSrc={coverImage}
            title={name}
            index={index}
            key={name}
            id={id}
            description={desc}
          />
        );
      });
      return renderedCategories;
    } else {
      return <p>Loading...</p>;
    }
  }
  render() {
    let renderedCategories = this.renderCategories();
    return (
      <div id="vedKendra">
        <div className="container p-0 ">
          <div className="text-left pt-2 py-lg-5 text-lg-center">
            <div>
              <h2 className="font-weight-bold mb-0">
                <img src={vedKendraLogo} height="50" alt="ved kendra" />
                <span
                  style={{
                    borderBottom: "2.3px solid #f6e1d5",
                    marginLeft: "10px",
                  }}
                >
                  Ved Kendra
                </span>
              </h2>
            </div>
          </div>
          {renderedCategories}
        </div>
      </div>
    );
  }
}
