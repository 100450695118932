import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
// HELPER FUNCS
import { fromKebabCase } from "../../helperFuncs/fromKebabCase";
import { toKebabCase } from "../../helperFuncs/toKebabCase";
import { baseUrl } from "../../helperFuncs/baseUrl";

function ProductCard({ productLink, categoryLink, image, title, salePrice }) {
  return (
    <div className="col-lg-4 mb-lg-4">
      <NavLink to={"/ved-kendra/" + categoryLink + "/" + productLink}>
        <div className="card" style={{ width: "100%" }}>
          <img
            src={image}
            className="card-img-top"
            alt="..."
            style={{ height: 220, width: 328, objetFit: "cover" }}
          />
          <div className="card-body">
            <div className="card-text">
              <div className="row no-gutters">
                <div className="col-8">
                  <p className="mb-0 font-weight-bold">{title}</p>
                  <p className="mb-0 font-weight-bold category-price">Rs. {salePrice}</p>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                  <span className="ml-auto fa fa-share"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
    this.getCategoryId = this.getCategoryId.bind(this);
  }
  componentDidMount() {
    this.getCategoryId();
  }
  // GET ID FROM CATEGORY NAME
  getCategoryId() {
    const { categoryLink } = this.props;
    const categoryName = fromKebabCase(categoryLink);
    axios.get(`${baseUrl.SERVER_URI}/vedkendra/category`).then((res) => {
      const categories = Object.values(res.data);
      const id = categories.filter(
        (obj) => obj.name.toLowerCase().trim() === categoryName.toLowerCase().trim()
      )[0].id;
      this.getProducts(id);
    });
  }
  getProducts(id) {
    if (id !== "") {
      // GET ALL PRODUCTS OF A CATEGORY-FROM CATEGORY ID
      axios.get(`${baseUrl.SERVER_URI}/vedkendra/products/${id}`).then((res) => {
        const products = Object.values(res.data);
        this.setState({
          products: products,
        });
      });
    }
  }
  renderProducts() {
    const { products } = this.state;
    const { categoryLink } = this.props;
    if (products.length) {
      const renderedProducts = products.map((product, index) => {
        const { title, image, salePrice } = product;
        const productLink = toKebabCase(title);
        return (
          <ProductCard
            title={title}
            image={image}
            salePrice={salePrice}
            categoryLink={categoryLink}
            productLink={productLink}
            key={productLink}
          />
        );
      });
      return renderedProducts;
    } else return <p>Loading...</p>;
  }
  render() {
    console.log(this.state);
    const { categoryLink } = this.props;
    const categoryName = fromKebabCase(categoryLink);
    const renderedProducts = this.renderProducts();
    return (
      <div className="vedKendraCategory">
        <div className="row justify-content-center my-lg-5 no-gutters">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-0">{categoryName}</h2>
              </div>
              <div className="col-12 mb-lg-2">
                <hr />
              </div>
              <div className="col-lg-2">
                <ul className="list-unstyled text-muted">
                  <li>{categoryName}</li>
                </ul>
              </div>
              <div className="col-lg-10">
                <div className="row">{renderedProducts}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
