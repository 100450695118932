import React from "react";

const ModalPhoneno = ({ handleSubmit }) => {
    return (
        <div
            className="modal fade"
            id="modalUpdatePhoneno"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Phone No.</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter new phone no"
                                    name="name"
                                    id="service_phoneno"
                                />
                            </div>
                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-2"
                                    data-dismiss="modal"
                                >
                                    Close
      </button>
                                <button
                                    type="button"
                                    className="btn btn-modal"
                                    onClick={() => {
                                        const user_phoneno = document.querySelector("#service_phoneno").value;
                                        handleSubmit(user_phoneno);
                                        alert("Phone No updated!!");
                                        window.location.reload();
                                    }}
                                >
                                    Submit
      </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div >

    )
}

export default ModalPhoneno
