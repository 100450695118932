import React from "react";

export default function Slider() {
  return (
    <div id="slider-section">
      <div
        id="slider"
        className="carousel slide"
        data-ride="carousel"
        data-pause="hover"
      >
        <ol className="carousel-indicators">
          <li data-target="#slider" data-slide-to="0" className="active"></li>
          <li data-target="#slider" data-slide-to="1"></li>
          <li data-target="#slider" data-slide-to="2"></li>
          <li data-target="#slider" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active" data-interval="3000">
            <a href="/ved-kendra/ashtamangal">
              <div className="slider-item" />
            </a>
            {/* <img src={img3} className="d-block w-100" alt="img3" /> */}
            {/* <h2 className="img-text">Penguins</h2> */}
          </div>
          <div className="carousel-item" data-interval="3000">
            <div className="slider-item" />
          </div>
          <div className="carousel-item" data-interval="3000">
            <div className="slider-item" />
          </div>
          <div className="carousel-item" data-interval="3000">
            <div className="slider-item" />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#slider"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#slider"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
