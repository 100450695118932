import React from "react";

export default function CardWelcomeCommon({ title, imgSrc }) {
  function Image({ img }) {
    return (
      <div className="col-6 p-4 p-lg-3">
        <a href="/ved-kendra/ashtamangal">
          <img
            src={img}
            className="card-img-top img-fluid"
            alt="recommendations"
            style={{ height: 120, width: 120, objectFit: "cover" }}
          />
        </a>
      </div>
    );
  }
  return (
    <>
      <div className="card" style={{ width: "90%" }}>
        <h4 className="ml-3 mt-3 color-change">{title}</h4>
        <div className="row no-gutters">
          <Image img={imgSrc[0]} />
          <Image img={imgSrc[1]} />
          <Image img={imgSrc[2]} />
          <Image img={imgSrc[3]} />
        </div>
      </div>
    </>
  );
}
