import React, { Component } from "react";
import axios from "axios";
import config from "../../helperFuncs/config";
import { fromKebabCase } from "../../helperFuncs/fromKebabCase";
import { toKebabCase } from "../../helperFuncs/toKebabCase";
import { baseUrl } from "../../helperFuncs/baseUrl";

function RenderProduct({ title, image, desc, salePrice, instock, pId }) {
  return (
    <>
      <div
        className="modal fade"
        id="bulkOrderModal"
        tabIndex="-1"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bulk Order Form</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email address"
                    name="email"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    name="password"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter company name"
                    name="company"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter quantity"
                    name="quantity"
                    id="bulkQuantity"
                  />
                </div>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    data-dismiss="modal">
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-modal"
                    onClick={() => {
                      const quantity = document.querySelector("#bulkQuantity")
                        .value;
                      checkCart(pId, quantity);
                    }}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <img src={image} className="img-fluid" alt="product" />
        </div>
        <div className="col-lg-5 d-flex align-items-center">
          <div className="vk-product-name">
            <h3 className="vk-product-title">{title}</h3>
            <h5 className="vk-product-price">Rs. {salePrice}</h5>
            <div>
              Quantity :{" "}
              <input
                min="1"
                defaultValue="1"
                type="number"
                className="form-control"
                id="quantityInput"
                style={{ width: 60, display: "inline-block" }}
              />
            </div>
            <div>
              <button
                className="btn btn-modal my-lg-3 mr-lg-2"
                onClick={() => {
                  const quantity = document.querySelector("#quantityInput")
                    .value;
                  checkCart(pId, quantity);
                }}>
                ADD TO CART
              </button>

              <button
                type="button"
                className="btn btn-modal nav-signin"
                data-toggle="modal"
                data-target="#bulkOrderModal">
                Bulk Order
              </button>
            </div>
            {instock === "yes" ? (
              <div />
            ) : (
              <div className="text-danger">Out of Stock</div>
            )}
            <p className="mb-0 vk-product-desc">{desc}</p>
          </div>
        </div>
      </div>
    </>
  );
}

function checkCart(pid, quantity) {
  const { uid } = localStorage;
  if (!uid) {
    alert("Please login to continue.");
    return;
  }
  console.log(`${baseUrl.SERVER_URI}/usercart/iscart`);
  axios
    .post(`${baseUrl.SERVER_URI}/usercart/iscart`, { uid, pid }, config)
    .then((res) => {
      console.log(res.data.message);
      if (res.data.message === "in cart") {
        alert("Product already in cart.");
        return;
      } else {
        addToCart(quantity, uid, pid);
      }
    })
    .catch((err) => console.log(err.response));
}

async function addToCart(quantity, uid, pid) {
  const sendData = {
    quantity,
    uid,
    pid,
  };
  await axios
    .post(`${baseUrl.SERVER_URI}/usercart/additem`, sendData, config)
    .then((res) => {
      console.log(res.data);
      alert("Product added to cart");
    })
    .catch((err) => {
      console.log(err);
    });
  setTimeout(() => (window.location.href = "/cart"), 3000);
}

// function checkCart(pid, quantity) {
//   const { uid } = localStorage;
//   const sendData = { uid, pid, quantity };
//   axios.post(`${baseUrl.SERVER_URI}/usercart/iscart`, sendData, config)
//     .then(res => {
//       if (res.data.message !== "in cart") {
//         addItemsToCart(pid, quantity)
//       } else {
//         console.log("in cart");
//       }
//     })
//     .catch(err => console.log("error: " + err))
// }

export default class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: {},
    };
    this.getProductDetails = this.getProductDetails.bind(this);
  }
  componentDidMount() {
    this.getCategoryId();
  }
  // GET CATEGORY ID FROM URL
  getCategoryId() {
    const { categoryLink } = this.props;
    const categoryName = fromKebabCase(categoryLink);
    axios
      .get(`${baseUrl.SERVER_URI}/vedkendra/category`, config)
      .then((res) => {
        const categories = Object.values(res.data);
        const categoryId = categories.filter(
          (obj) =>
            obj.name.toLowerCase().trim() === categoryName.toLowerCase().trim()
        )[0].id;
        this.getProductId(categoryId);
      });
  }
  // GET PRODUCT ID FROM PRODUCT LINK(URL) AND CAETGORY ID
  getProductId(categoryId) {
    const { productLink } = this.props;
    const productName = fromKebabCase(productLink).trim().toLowerCase();
    if (categoryId !== undefined) {
      axios
        .get(`${baseUrl.SERVER_URI}/vedkendra/products/${categoryId}`, config)
        .then((res) => {
          const products = Object.values(res.data);
          const productId = products.filter(
            (obj) =>
              fromKebabCase(toKebabCase(obj.title)).trim().toLowerCase() ===
              productName
          )[0].pId;
          this.getProductDetails(productId);
        });
    }
  }
  getProductDetails(productId) {
    axios
      .get(`${baseUrl.SERVER_URI}/vedkendra/product/${productId}`, config)
      .then((res) => {
        this.setState({
          productDetails: { ...res.data },
        });
      });
  }
  renderProduct() {
    const { productDetails } = this.state;
    if (Object.values(productDetails).length) {
      const {
        // categoryName,
        desc,
        image,
        salePrice,
        title,
        instock,
        pId,
      } = productDetails;
      return (
        <RenderProduct
          desc={desc}
          image={image}
          salePrice={salePrice}
          title={title}
          instock={instock}
          pId={pId}
        />
      );
    }
  }
  render() {
    console.log(this.state);
    const renderedProduct = this.renderProduct();
    return (
      <div>
        <div className="row justify-content-center no-gutters my-lg-5">
          <div className="col-10">{renderedProduct}</div>
        </div>
      </div>
    );
  }
}
