import React from 'react'

const Cancellation = () => {
    return (

        <>
            <div>
                <div className="my-5">
                    <div className="row no-gutters">
                        <div className="col-10 offset-1">
                            <div><h1>Cancellation Policy</h1></div>
                            <div>
                                <p><strong>Buyer Policies:</strong>  As a buyer, you need to accept and follow our rules and policies before you can purchase an item. This helps ensure a smoother, more enjoyable transaction for both buyers and sellers.</p>
                                <p>Cancellation Policy for Buyers:</p>
                                <ul>
                                    <li>Once an order is successfully placed but not confirmed, it can be cancelled by either the buyer or the seller.</li>
                                    <li>Once an order is successfully placed and confirmed, it can be cancelled by the buyer only.</li>
                                    <li>An order cannot be cancelled once it is dispatched or while in transit or is delivered.</li>
                                </ul>
                            </div>
                            <div>
                                <p><strong>Return Policy for Buyers:</strong></p>
                                <ul>
                                    <li>Return requests will be accepted only when an order is delivered successfully.</li>
                                    <li>Returns will be accepted up to 10 days post successful delivery of any order i.e. delivery + 10 days.</li>
                                    <li>Buyer can return the order by calling Pro Tech Inc customer support at +91-_____________ or writing to _________________________</li>
                                    <li>A return will only be accepted by the seller in case of unused and undamaged product.</li>
                                    <li>Once an order is returned to the seller, the refund will be processed within 7 working days.</li>
                                </ul>
                                <p>Our policy lasts 10 days. If 10 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.
                                To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
</p>
                                <p>To complete your return, we require a receipt or proof of purchase.
                                Please do not send your purchase back to the manufacturer.
                                There are certain situations where only partial refunds are granted: (if applicable)
                                Product with obvious signs of use
                                Any item not in its original condition, is damaged or missing parts for reasons not due to our error.
                                Any item that is returned more than 10 days after delivery
</p>
                            </div>
                            <div>
                                <p><strong>Refunds (if applicable)</strong></p>
                                <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
                                If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 7 working days.
</p>
                            </div>
                            <div><p><strong>Late or missing refunds (if applicable)</strong></p>
                                <p>If you haven’t received a refund yet, first check your bank account again.</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cancellation
