import React, { Component } from "react";
import axios from "axios";
import config from "../../helperFuncs/config";
// COMPONENTS
import ItemContainer from "./ItemContainer";
import ZeroItems from "./ZeroItems";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../helperFuncs/baseUrl";

const { uid } = localStorage;

export default class DisplayCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: [],
      isLoading: true,
      subtotal: 0,
      isCartEmpty: false
    };
    this.getCart = this.getCart.bind(this);
    this.getSubtotal = this.getSubtotal.bind(this);
  }
  componentDidMount() {
    this.getCart();
  }
  // subtotal
  getSubtotal() {
    axios
      .post(`${baseUrl.SERVER_URI}/order/buynow`, { uid }, config)
      .then((res) => {
        console.log(res.data);
        this.getCart();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getCart() {
    axios
      .post(`${baseUrl.SERVER_URI}/usercart/readitem`, { uid }, config)
      .then((res) => {
        let productDetails = Object.values(res.data);
        productDetails.pop();
        console.log(res.data);
        // store pid and quantity only for state
        let stateDetails = productDetails.map((product) => {
          const { pID, quantity } = product;
          return {
            pID: pID,
            quantity,
          };
        });
        console.log(stateDetails);
        this.setState({
          productDetails: [...stateDetails],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 404 && err.response.data.status === "no items in cart") {
          console.log("no items");
          this.setState({ isCartEmpty: true, isLoading: false })
        }
      });
  }
  render() {
    console.log(this.state);
    const { productDetails: items, isLoading, subtotal, isCartEmpty } = this.state;
    let RenderedItems;
    if (isLoading) {
      RenderedItems = () => <p>Loading...</p>;
    } else {
      if (items.length === 0 || isCartEmpty) {
        RenderedItems = () => <ZeroItems />;
      } else {
        RenderedItems = () => (
          <ItemContainer items={items} subtotal={subtotal} />
        );
      }
    }
    return (
      <div className="py-lg-5">
        <div className="row w-100">
          <div className="col-lg-10 mx-auto">
            <RenderedItems />
            <div className="float-right">
              {!isCartEmpty &&
                <NavLink to="/payment/selectaddress">
                  <button className="btn btn-modal">Proceed to Checkout</button>
                </NavLink>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
