import React from "react";
import Item from "./Item";

const renderItems = (items) => {
  return items.map((item) => {
    const { pID, quantity } = item;
    return <Item pID={pID} quantity={quantity} key={pID} />;
  });
};

export default function ItemContainer({ items }) {
  const renderedItems = renderItems(items);
  return (
    <div>
      <div className="mb-lg-4">
        <h4>Shopping Cart</h4>
      </div>
      {renderedItems}
    </div>
  );
}
