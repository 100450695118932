import React, { Component } from "react";
import axios from "axios";
import { baseUrl } from "../../helperFuncs/baseUrl";
import config from "../../helperFuncs/config";

export default class PaymentRazorpay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: {},
    };
  }
  componentDidMount() {
    this.getDetails();
  }
  getDetails() {
    console.log("get details");
    const data = { uid: localStorage.uid };
    axios
      .post(`${baseUrl.SERVER_URI}/order/razorpay`, data, config)
      .then((res) => {
        console.log(res)
        this.setState({
          orderDetails: { ...res.data.order },
        });
      })
      .catch((err) => console.log(err));
  }
  renderDetails() {
    const { orderDetails } = this.state;
    if (Object.keys(orderDetails).length > 0) {
      const {
        id: orderId,
        amount,
        amount_paid,
        amount_due,
        receipt,
        notes,
      } = orderDetails;
      return (
        <>
          <h5>Your order was successful!!</h5>
          <h5>Order ID:{orderId}</h5>
          <h5>Amount Details</h5>
          <ul className="list-unstyled">
            <li>Total amount: Rs.{amount / 100}</li>
            <li>Amount Paid: Rs.{amount_paid / 100}</li>
            <li>Amount Due: Rs.{amount_due / 100}</li>
          </ul>
          <p>Receipt No: {receipt}</p>
          {notes.length > 0 ? (
            <ul className="list-unstyled">
              {notes.map((note, index) => (
                <li>{note}</li>
              ))}
            </ul>
          ) : null}
        </>
      );
    } else {
      return <p>Loading..</p>;
    }
  }
  render() {
    console.log(this.state);
    const renderedDetails = this.renderDetails();
    return (
      <div className="py-2">
        <div className="row">
          <div className="col-12 col-lg-10 mx-auto">{renderedDetails}</div>
        </div>
      </div>
    );
  }
}
