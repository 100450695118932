import React from "react";
import Card from "./Card";
import vedChakra from "../../images/vedChakra.png";
import vedKendra from "../../images/vedKendra.png";
import vedMudra from "../../images/vedMudra.png";
import vedVastu from "../../images/vedVastu.png";
import { NavLink } from "react-router-dom";

export default function CardSection() {
  return (
    <div id="cards-section">
      <div className="row no-gutters w-100" style={{ maxWidth: "100%" }}>
        <div className="col-6 col-lg-3 d-flex justify-content-center p-1 p-lg-4">
          <NavLink to="/ved-kendra">
            <Card
              name="Ved Kendra"
              imgSrc={vedKendra}
              content="Ved Kendra provides you a place where we conduct different types of lectures, lecture demonstrations, and homas, related to Vedas, astrology, religion, music, and philosophy. "
            />
          </NavLink>
        </div>
        <div className="col-6 col-lg-3 d-flex justify-content-center p-1 p-lg-4">
          <a href="https://play.google.com/store/apps/details?id=com.vedworld.vedworld" target='blank'>
            <Card
              name="Ved Chakra"
              imgSrc={vedChakra}
              content="Ved World works with a team of renowned pandits and translators with their special energies will scan your body through electromagnetic waves to avoid all the harmful energies throughout. "
            />
            </a>
        </div>
        <div className="col-6 col-lg-3 d-flex justify-content-center p-1 p-lg-4">
          <NavLink to="/ved-mudra">
            <Card
              name="Ved Mudra"
              imgSrc={vedMudra}
              content="Once you buy our products you will be provided with cashback in your wallet  which can be availed on your next purchase."
            />
          </NavLink>
        </div>
        <div className="col-6 col-lg-3 d-flex justify-content-center p-1 p-lg-4">
        <a href="https://play.google.com/store/apps/details?id=com.vedworld.vedworld" target='blank'>
          <Card
              name="Ved Vastu"
              imgSrc={vedVastu}
              content="Scanning the internal and external surroundings which involve overall measurements and compass reading of your property to prevent negative energies from entering your surroundings. "
            />
          </a>
        </div>
      </div>
    </div>
  );
}
