import React from "react";

export default function privacyPolicy() {
  return (
    <div id="about-section">
      <div className="container p-0">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                Privacy Policy
              </span>
            </h2>
          </div>
        </div>
        <div style={{marginBottom:"20px"}}>
            By using our Services, you consent to our use of your data under this Privacy Policy.
            <br/><br/>
            Our registered users (“Members”) share their professional identities, engage with their network, exchange knowledge and professional insights, post and view relevant content, learn and find business and career opportunities. Content on some of our services is available to non-members (“Visitors”).
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
              Data Controllers
            </div>
            <div>
              If you reside in the United States, you are entering into the User Agreement with Ved World Private Limited, who will be responsible for your personal data provided to, or collected by or for, our Services.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            Services
            </div>
            <div>
            This Privacy Policy applies to vedworld.com,   ved world apps, and other related sites, apps, communications and services (“Services”), including off-site Services, such as our ad services, but excluding services that state that they are offered under a different privacy policy.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            Consent
            </div>
            <div>
            If you use our Services, you consent to the collection, use and sharing of your personal data under this Privacy Policy (which includes our Cookie Policy and other documents referenced in this Privacy Policy) and agree to the User Agreement. We provide you choices that allow you to opt-out or control how we use and share your data.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            Change
            </div>
            <div>
            We may modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that you are consenting to the updated Privacy Policy.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.1 Information You Provide To Us
            </div>
            <div>
            Registration
            <br/>
            To create an account you provide data including your name, email address and/or mobile number, and a password. If you register for a premium Service, we ask you for payment (e.g., credit card) and billing information.
            <br/><br/>
            Profile
            <br/>
            You have choices about the information on your profile, such as your education, work experience, skills, photo, city or area and endorsements. Profile information helps you to get more from our Services, including helping other users and business opportunities find you. It’s your choice whether to include sensitive information on your profile. Please do not post or add personal data to your profile that you would not want to be publicly available.
            <br/><br/>
            Posting and Uploading
            <br/>
            We collect personal data from you when you provide, post or upload it to our Services, such as when you fill out a form, respond to a survey (e.g., Members’ salary, Professional experiences, abilities, etc.,), submit a resume and apply for or save jobs or send invitations. If you opt to import your address book, we receive your contacts (including contact information your service provider(s) or app automatically added to your address book when you communicated with addresses or numbers not already in your list). If you sync your email or calendars with our Services, we will collect your “email header” and calendar meeting information (e.g. times, places, attendees and contacts).
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.2 Information From Others
            </div>
            <div>
            Content and News
            <br/>
            You and others may post content that includes information about you on our Services (as part of blog posts, feed updates and comments, videos). Unless you opt-out, we collect public information about you, such as, but not limited to, professional-related news and accomplishments (e.g., patents granted, professional recognition, conference speakers, projects, etc.) and make it available as part of our Services (e.g. suggestions for your profile, or notifications of mentions in the news/feed/wall).
            <br/><br/>
            Contact and Calendar Information
            <br/>
            We receive personal data about you when others import or sync their address book or calendar with our Services, or send messages using our Services (including invites or connection requests).
            <br/><br/>
            Partners
            <br/>
            We receive personal data about you when you use the services of our customers and partners.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.3 Service Use
            </div>
            <div>
            We log usage data when you visit or otherwise use our Services, including our sites, app and platform technology (e.g., our off-site plugins), such as when you view or click on content or ads (on or off our sites and apps), perform a search, install one of our mobile apps, share articles or apply for jobs. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.4 Cookies, Web Beacons and Other Similar Technologies
            </div>
            <div>
            As further described in our Cookie Policy, we use cookies and similar technologies (e.g., web beacons, pixels, ad tags and device identifiers) to recognize you and/or your device(s) on, off and across different Services and devices. We also allow some others to use cookies as described in our Cookie Policy. You can control cookies through your browser settings and other tools. You can also opt out from our use of cookies and similar technologies that track your behavior on the sites of others for third party advertising.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.5 Your Device and Location
            </div>
            <div>
            When you visit or leave our Services (including our plugins or cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to next. We also get information about your IP address, proxy server, operating system, web browser and add-ons, device identifier and features, and/or ISP or your mobile carrier. If you use our Services from a mobile device, that device will send us data about your location. Most devices allow you to prevent location data from being sent to us and we honor your settings.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.6 Messages
            </div>
            <div>
            We collect information about you when you send, receive, or engage with messages in connection with our Services. For example, if you get a Ved World connection request, we track whether you have acted on it and will send you reminders. We also use automatic scanning technology on messages.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.7 Workplace Provided Information
            </div>
            <div>
            An employer (or other person or entity procuring our Services for your use) may provide us information about their employees or contractors who make use of these Services. For example, we will get contact information for “Organization's Page” administrators and for authorizing users of our premium Services.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.8 Sites and Services of Others
            </div>
            <div>
            We receive information about your visits and interaction with services provided by others when you log-in with Ved World or visit others’ services that include our plugins (such as “Share on Ved World” or “Apply with Ved World”), ads, cookies or similar technologies.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            1.9 Other
            </div>
            <div>
            Our Services are dynamic and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we use your data, we will notify you and may also modify this Privacy Policy.
            <br/>
            How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide, support, personalize and make our Services (including ads) more relevant and useful to you and others.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.1 Services
            </div>
            <div>
            We use your data to authenticate you and authorize access to our Services.
            <br/>
            Stay Connected
            <br/>
            We will use data about you (such as profiles you have viewed or data provided through address book uploads or partner integrations) to suggest connections for you and others (e.g. Members who share your contacts) and enable you to invite others to become a Member and connect with you. You are also allowing us to allow us to use your precise location or proximity to others to suggest other nearby Members for you to connect with. It is your choice whether to invite someone to our Services, send a connection request, or allow another Member to become your connection. When you invite someone to connect with you, your invitation will include your name, photo, network and contact information. We will send invitation reminders to the person you invited. You can choose whether or not to share your own list of connections with your connections.
            <br/><br/>
            Stay Informed
            <br/>
            Our Services allow you to stay informed about news, events and ideas regarding the topics you care about, and from people  you respect. Our Services also allow you to improve your reading/writing/learning/teaching/sharing/communication skills, or learn new ones. We use the information about you to recommend relevant content across our Services, suggest skills you may have to add to your profile and skills that you might need to pursue your next opportunity. So, if you let us know that you are interested in a new skill (e.g., by watching a video), we will use this information to personalize content in your feed, suggest that you follow certain members on our site, or watch related learning content to help you towards that new skill. We use your content, activity and other data, including your name and picture, to provide notices to your network and others. For example, subject to your settings, we may notify others that you have updated your profile, posted a blog, took a social action, made new connections or were mentioned in the news.
            <br/><br/>
            Career
            <br/>
            Our Services allow you to explore careers, evaluate educational opportunities, and seek out, and be found for, career opportunities. Your profile can be found by those looking to hire (for a job or a specific task) or be hired by you. We will use your data to recommend jobs, show you and others who work at a company, in an industry, function or location or have certain skills and connections. You can signal that you are interested in changing jobs and share information with job recruiters. We may use your profile and activity to recommend jobs to you and you to recruiters.
            <br/><br/>
            Productivity
            <br/>
            Our Services allow you to communicate with others and schedule and prepare meetings with them. Subject to your settings, we scan messages to provide “bots” or similar tools that facilitate tasks such as scheduling meetings, draft responses, summarize messages or recommend next steps.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.2 Premium Services
            </div>
            <div>
            We sell premium Services that provide our customers customized-search functionality and tools (including messaging and activity alerts) as part of our solutions. These subscribers can use your available profile information from your profile, in order to manage sales leads or talent, unless you opt out. We do not provide any special information to these subscribers as part of these premium Services without your consent. The data provided about you by these subscribers is subject to the policies of those subscribers.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.3 Communications
            </div>
            <div>
            We will contact you through email, notices posted on our websites or apps, messages to your ved world inbox, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use the Services, network updates, reminders, job suggestions and promotional messages from us and our partners. You may change your communication preferences at any time. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.
            <br/>
            We also enable communications between you and others through our Services, including for example invitations, mail, groups, events, discussions, sharing information and messages between buddies/connections.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.4 Advertising
            </div>
            <div>
            We target (and measure the performance of) ads to Members, Visitors and others both on and off of our Services through a variety of ad networks and exchanges, using the following data, whether separately or combined:
            <br/>
·Data from advertising technologies on and off of our Services, like web beacons, pixels, ad tags, cookies, and device identifiers;
<br/>
·Member-provided information (e.g., contact information, title and industry);
<br/>
·Data from your use of our Services (e.g., search history, feed, content you read, who you follow or is following you, connections, groups participation, page visits, videos you watch, clicking on an ad, etc.), including as already described above;
<br/>
·Information from others (e.g. advertising partners, publishers and data aggregators);
<br/>
·Information inferred from data described above (e.g., using job titles to infer age, industry, seniority, and compensation bracket; or names to infer gender).
<br/><br/>
We will show you ads called sponsored content which look like similar non-sponsored content, except that they are labelled ads or sponsored. If you take an action (such as like, comment or share) on these ads, your action is associated with your name and viewable by others, including the ad provider.
<br/><br/>
Ad Choices
<br/>
We adhere to self-regulatory principles for interest-based advertising and participate in industry opt outs from such ads. This does not opt you out of receiving advertising - you will continue to get generic ads or ads by advertisers not listed with these self regulatory tools.
<br/><br/>
Info to Advertisers
<br/>
We do not share your personal data with any third-party advertisers or (other than hashed or device identifiers, to the extent they are personal data in some countries) ad networks for their advertising without your separate permission. However, if you click on an ad, the ad poster will know you visited the page that you clicked on. Also, advertising partners can associate personal data collected by the advertiser directly from you with our cookies and similar technologies. In such instances, we seek to contractually require such advertisers to obtain your explicit, opt-in consent before doing so, and it is the sole responsibility of the advertiser to stick to the legal guidelines in these matters.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.5 Marketing
            </div>
            <div>
            We use data and content about Members for invitations and communications promoting membership and network growth, engagement and our Services.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.6 Developing Services and Research
            </div>
            <div>
            Service Development
            <br/>
We use data, including public feedback, to conduct research and development for the further development of our Services in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth and engagement on our Services, and help connect users to each other and to creative, literary, social, economic and all kinds of legally allowed opportunities.
<br/><br/>
Other Research
<br/>
We seek to create various opportunities for members and to help them be better human beings, more productive and successful. We use the data available to us to research social, economic and workplace trends such as reading habits and trends, availability, sales and usage of books, and all other aspects related to books and policies that help bridge the gap in various institutions and geographic areas. In some cases, we can work with trusted third parties to perform this research, under controls that are designed to protect your privacy. We publish or allow others to publish insights, presented as aggregated data rather than personal data.
<br/><br/>
Surveys
<br/>
Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys and you have choices about the information you provide. You may opt out of survey invitations.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.7 Customer Support
            </div>
            <div>
            We use the data (which can include your communications) needed to investigate, respond to and resolve complaints and Service issues (e.g., bugs).
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.8 Aggregate Insights
            </div>
            <div>
            We use your information to produce aggregate insights that do not identify you. For example we may use your data to generate statistics about our users, their profession or industry, the number of ad impressions served or clicked on, or the demographic distribution of visitors to a site.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            2.9 Security and Investigations
            </div>
            <div>
            We use your data (including your communications) if we think it’s necessary for security purposes or to investigate possible fraud or other violations of our User Agreement or this Privacy Policy and/or attempts to harm our Members or Visitors.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.1 Our Services
            </div>
            <div>
            Profile
            <br/>
Your profile is fully visible to all Members and customers of our Services. As detailed in our policy guidelines like this document and user agreement, your settings, degree of connection with the viewing Member, the subscriptions they may have, their usage of our Services, access channels and search types (e.g., by name or by keyword) impact the availability of your profile and certain fields.
<br/>
Posts, Likes, Follows, Comments, Messages
<br/><br/>
Our Services allow viewing and sharing information including through posts, follows, likes, comments, groups and events, etc.
<br/>
·When you share a post (e.g., an update, video or blog), the default (which you can change) is to share it publicly. Others who are not your connections will be able to find (including through search engines) and see your post.
<br/>
·When you like, comment on or share another’s post, others will see it, including the person who initiated the post.
<br/>
·In a group, posts are visible to others in the group. Your membership in groups is public and part of your profile unless you change the default settings.
<br/>
·Any information you share through any of the pages on our Services will be viewable by it and others who visit those pages.
<br/>
·When you follow a person or organization, you are visible to others and that “page owner” as a follower. We provide aggregate insights about the followers and viewers to the respective page owners.
<br/>
·We let senders know when you act on their message, subject to your settings where applicable.

 
<br/><br/>
Enterprise Accounts
<br/>
Your employer may offer you access to our enterprise Services when these are launched. Your employer can review and manage your use of such enterprise Services.

Detailed policies regarding these services will be available as and when such services are launched by Ved World.            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.2 Communication Archival
            </div>
            <div>
            Some Members (or their employers) need, for legal or professional compliance, to archive their communications and social media activity, and will use services of others to provide these archival services.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.3 Others’ Services
            </div>
            <div>
            When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. For example, you may link your Twitter or others account to share content from our Services into these other services, or your email provider may give you the option to upload your Ved World contacts into its own service. You may revoke the link with such accounts.
<br/>
Subject to your settings, excerpts from your profile will appear on the services of others (e.g., search engine results, mail and calendar applications that show a user a “mini” Ved World profile of the person they are meeting or messaging, social media aggregators, talent and lead managers). “Old” profile information remains on these services until they update their data cache with changes you made to your profile.            </div>
<div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
3.4 Related Services
            </div>
            <div>
            We will share your personal data with our affiliates to provide and develop our Services. We combine information internally across the different Services covered by this Privacy Policy. For example, Ved World could personalize your feed or job recommendations based on your usage history, because we are able to identify you across different Services using cookies or similar technologies.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.5 Service Providers
            </div>
            <div>
            We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated to not to disclose or use it for other purposes.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.6 Legal Disclosures
            </div>
            <div>
            It is possible that we will need to disclose information about you when required by law, legal-notice, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you, (3) investigate and defend ourselves against any third-party claims or allegations, (4) protect the security or integrity of our Service (such as by sharing with entities facing similar threats); or (5) exercise or protect the rights and safety of Ved World, our Members, personnel, or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are over-broad, vague or lack proper authority, but we do not promise to challenge every demand.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            3.7 Change in Control or Sale
            </div>
            <div>
            We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            4.1 Data Retention
            </div>
            <div>
            We retain the personal data you provide while your account is in existence or as needed to provide you Services. Even if you only use our Services for a while, we will retain your information and keep your profile open until you decide to close your account. In some cases we choose to retain certain information  in a depersonalized or aggregated form.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            4.2 Rights to Access and Control Your Personal Data
            </div>
            <div>
            We provide many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your posts to advertising opt-outs and communication controls. We offer access to the personal data we have about you (for our associated services).
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            4.3 Account Closure
            </div>
            <div>
            If you choose to close your Ved World account, your personal data will generally stop being visible to others on our Services within 1 week. We generally delete closed account information within 30 days of account closure, except as noted below.
<br/>
We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our User Agreement, or fulfill your request to “unsubscribe” from further messages from us. We will retain de-personalized information after your account has been closed.
<br/>
Information you have shared with others (e.g., through sharing, updates, group and other posts) will remain visible after you closed your account or deleted the information from your own profile or mailbox, and we do not control data that other Members copied out of our Services. Groups content associated with closed accounts will show an unknown user as the source. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.

            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            5.1. Security
            </div>
            <div>
            We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            5.2. Contact Information
            </div>
            <div>
            If you have questions or complaints regarding this Policy, please first contact Ved World Private Limited at vedworldlegal@gmail.com. You can also reach us by physical mail by sending us a mail at our registered office/corporate office.</div>
        
        </div>
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                 Refund Policy
              </span>
            </h2>
          </div>
        </div>
        <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            Refund
            </div>
            <div>
            we will process all refunds within 3-10 working days. National holidays, bank holidays and saturday,sundays are excluded. We cannot be held responsible for Banking Delays.
            </div>
            <div style={{fontWeight:800,fontSize:'1.5rem',marginTop:"12px"}}>
            Cancellation
            </div>
            <div  style={{marginBottom:"50px"}}>
            We will not accept cancellations beyond 48 hours once the product has been ordered for.</div>
            </div>
    </div>
  );
}
