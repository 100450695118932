import React from "react";

export default function Cart({ cartInfo }) {
  const { productName, quantity, salePrice } = cartInfo;
  return (
    <div className="mb-2">
      <div className="card p-3">
        <div>{productName} </div>
        <div>Quantity : {quantity}</div>
        <div>Rs. {salePrice}</div>
      </div>
    </div>
  );
}
