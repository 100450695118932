import axios from "axios";
import React, { Component } from "react";
import { baseUrl } from "../../helperFuncs/baseUrl";

export default class ModalTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // signup
      email: "",
      password: "",
      confirmPassword: "",
      moreDetails: false,
      errors: {},
      // login
      loginEmail: "",
      loginPassword: "",
      loginErrors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit(event) {
    const { title } = this.props;
    event.preventDefault();
    const { email, password, confirmPassword } = this.state;
    // login
    if (title === "Login") {
      const userData = {
        email,
        password,
      };
      axios
        .post(`${baseUrl.SERVER_URI}/users/login`, userData)
        .then((res) => {
          console.log(res);
          localStorage.setItem("uid", res.data.uid);
          localStorage.setItem("token", `Bearer ${res.data.token}`);
          alert("Successfully logged in!");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          const { response } = err
          console.log(response);
          const { data } = response;
          // validation of email
          if (data.email !== "") {
            this.setState(state => ({
              errors: { ...state.errors, email: data.email }
            }))
          }
          // validation of password
          if (data.password !== "") {
            this.setState(state => ({
              errors: { ...state.errors, password: data.password }
            }))
          }
          // password wrong
          if (data.error === "auth/wrong-password") {
            this.setState(state => ({
              errors: { ...state.errors, password: "Wrong password. Please check your password and try again." }
            }))
          }
          // email not registered
          if (data.error === "auth/invalid-email") {
            this.setState(state => ({
              errors: { ...state.errors, email: "Email not registered. Please sign up." }
            }))
          }
        });
    }
    // signup
    else {
      const userData = {
        email,
        password,
        confirmPassword,
      };
      axios
        .post(`${baseUrl.SERVER_URI}/users/signup`, userData)
        .then((res) => {
          console.log(res);
          alert("Successfully signed up!");
          window.location.reload();
        })
        .catch((err) => {
          const { response } = err
          const { data, status } = response
          console.log(response);
          if (status === 400) {
            console.log("bad request");
            // validation of email
            if (data.email !== "") {
              this.setState(state => ({
                errors: { ...state.errors, email: data.email }
              }))
            }
            // validation of password
            if (data.password !== "") {
              this.setState(state => ({
                errors: { ...state.errors, password: data.password }
              }))
            }
            // email already in use
            if (data.message === "Email is already used") {
              this.setState(state => ({
                errors: { ...state.errors, email: "Email already in use. Please login." }
              }))
            }
            if (data.confirmPassword === "Passwords do not match") {
              this.setState(state => ({
                errors: { ...state.errors, confirmPassword: "Passwords do not match" }
              }))
            }
          }
        });
    }
  }
  render() {
    const { title } = this.props;
    const { errors } = this.state;
    return (
      <>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Enter email address"
                value={this.state.email}
                onChange={this.handleChange}
                name="email"
              />
              {errors.email ? (
                <small className="form-text text-danger">
                  {errors.email}
                </small>
              ) : <div />}
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                value={this.state.password}
                onChange={this.handleChange}
                name="password"
              />
              {errors.password ? (
                <small className="form-text text-danger">
                  {errors.password}
                </small>
              ) : <div />}
            </div>
            <div className="form-group">
              {title === "Sign up" ? (
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password again"
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    name="confirmPassword"
                  />
                  {errors.confirmPassword ? (
                    <small className="form-text text-danger">
                      {errors.confirmPassword}
                    </small>
                  ) : <div />}
                </div>
              ) : (
                  <div>
                    {/* <small>
                    Don't have an account?
                    <button
                      type="button"
                      className="btn btn-modal nav-signin"
                      data-toggle="modal"
                      data-target="#signupModal"
                    >
                      <small>Sign up</small>
                    </button>
                    here
                  </small> */}
                  </div>
                )}
            </div>
            <hr />
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-modal"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
