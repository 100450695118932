import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";

export default function Footer() {
  return (
    <div id="footer-section">
      <div className="container h-100">
        <div className="row align-items-end h-100 justify-content-around no-gutters pb-3">
          <div className="col-12 col-lg-2" id="footer-links">
            <ul className="mx-auto list-unstyled d-flex d-lg-block mb-0">
            <li className="mx-2 mx-lg-0">
                <NavLink exact to="/terms-and-conditions">
                  Terms &amp; Conditons
                </NavLink>
              </li>
              <li className="mx-2 mx-lg-0">
                <NavLink exact to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </li>
              <li className="mr-auto ml-2 mx-lg-0">
                <NavLink exact to="/privacy-policy">
                  Return Policy
                </NavLink>
              </li>
              <li className="mr-auto ml-2 mx-lg-0">
                <NavLink exact to="/shipping-policy">
                  Shipping Policy
                </NavLink>
              </li>
              <li className="mx-2 mx-lg-0">
                <NavLink exact to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 d-none d-lg-block">
            <img src={logo} alt="logo" width="150px" />
          </div>
          <div id="social-handles" className="col-12 col-lg-2">
            <div>
              <ul className="list-unstyled d-flex justify-content-end mb-0">
                <li className="mx-3">
                <a href="https://www.facebook.com/thevedworld">
                  <span className="fa fa-facebook"></span>
                </a>
                </li>
                <li className="mx-3">
                <a href="https://www.instagram.com/thevedworld/">
                  <span className="fa fa-instagram"></span>
                </a>
                </li>
                <li className="mr-auto ml-3">
                <a href="https://www.linkedin.com/company/ved-world/">
                  <span className="fa fa-linkedin"></span>
                </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 w-100 text-center text-white">
            <small>Copyright Ved World Pvt. Ltd.</small>
          </div>
        </div>
      </div>
    </div>
  );
}
