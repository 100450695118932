import React, { Component } from "react";
import PaymentGateway from "./PaymentGateway";

// function getLink() {
//   const query = document.querySelector("input[name=modeOfPayment]:checked");
//   if (query !== null) {
//     const modeOfPayment = query.value;
//     const link = `/payment/modeofpayment/${modeOfPayment}`;
//     console.log(link);
//     return link;
//   }
//   return null;
// }

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modeOfPayment: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      modeOfPayment: e.target.value,
    });
  }
  render() {
    return (
      <div>
        <div>
          <h6>Payment Method</h6>
        </div>
        <div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="COD"
              name="modeOfPayment"
              onChange={this.onChange}
              className="custom-control-input"
              value="COD"
            />
            <label className="custom-control-label" htmlFor="COD">
              Cash on Delivery
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="razorpay"
              name="modeOfPayment"
              onChange={this.onChange}
              className="custom-control-input"
              value="razorpay"
              checked
            />
            <label className="custom-control-label" htmlFor="razorpay">
              Pay using Razorpay
            </label>
          </div>
        </div>
        <div className="mt-2">
          <div className="d-flex justify-content-end">
            {/* {getLink() !== null ? (
              <NavLink to={getLink()}> */}
            <PaymentGateway />
            {/* </NavLink>
            ) : (
                <div />
              )} */}
          </div>
        </div>
      </div>
    );
  }
}
