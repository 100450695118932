import React from "react";
import axios from "axios";
import { baseUrl } from "../../helperFuncs/baseUrl";
import config from "../../helperFuncs/config";

const url = baseUrl.SERVER_URI;
const { uid, aid } = localStorage;

function confirmPayment(razorpayPaymentID) {
  const data = { uid, aid, razorpayPaymentID, modeOfPayment: "razorpay" }
  axios.post(`${url}/order/confirmpayment`, data, config)
    .then(res => {
      console.log(res);
      if (res.data.message === "order places successfully") {
        const data2 = { uid, orderID: res.data.orderID }
        axios.post(`${url}/delhivery/trackorder`, data2, config)
          .then(res => {
            console.log(res);
          })
      }
    })
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function PaymentGateway() {
  async function displayRazorpay() {
    // wait for razorpay sdk to load
    const result = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!result) {
      alert("Razorpay SDK failed to load!!");
      return;
    }

    const data = { uid: localStorage.uid };
    axios.post(`${url}/order/razorpay`, data, config)
      .then(res => {
        const {
          orderId,
          amount,
        } = res.data.order;

        var options = {
          key: "rzp_live_Wg7Um8K2mqoXVp",
          name: "Payment for order at Ved World",
          description: "Order payment",
          image: "/favicon-32x32.png",
          order_id: orderId,
          amount: amount,
          handler: function (response) {
            console.log(response);
            confirmPayment(response.razorpay_payment_id);
          },
          //   prefill: {
          //     name: "Gaurav Kumar",
          //     email: "gaurav.kumar@example.com",
          //     contact: "9999999999",
          //   },
          //   notes: {
          //     address: "Razorpay Corporate Office",
          //   },
          //   theme: {
          //     color: "#F37254",
          //   },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      })

  }

  return (
    <div>
      <button className="btn btn-modal" onClick={displayRazorpay}>
        Proceed To Payment
      </button>
    </div>
  );
}
