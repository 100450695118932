import React from "react";
import noUser from "../../images/noImg.png";
import shoppingCart from "../../images/shoppingCart.png";
import packages from "../../images/packages.jpg";
import { NavLink } from "react-router-dom";

export default function CardWelcome({ name }) {
  function Card({ image, text }) {
    return (
      <>
        <div className="col-6 p-4">
          <img src={image} alt="card" className="img-fluid" />
        </div>
        <div className="col-6 d-flex align-items-center">
          <p style={{ color: "Black" }}>{text}</p>
        </div>
      </>
    );
  }
  return (
    <div className="h-100 d-flex justify-content-center">
      <div className="card h-100 p-4 my-3 my-lg-0" style={{ width: "90%" }}>
        <div className="row">
          <div className="col-lg-4">
            <img
              src={noUser}
              className="card-img-top rounded-circle"
              alt="profile"
            />
          </div>
          <div className="col-8 d-flex align-items-center">
            <h4 className="color-change">Hi, {name}</h4>
          </div>
        </div>
        <div className="card-body p-0 pt-3">
          <div className="row">
            <NavLink to="/cart" className="d-flex">

              <Card image={shoppingCart} text="View Cart" />
            </NavLink>
            <NavLink to="/yourorders" className="d-flex">
              <Card image={packages} text="View all orders" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
