import React from "react";

export default function termsConditons() {
  return (
    <div id="about-section">
      <div className="container p-0">
        <div className="text-left pt-2 py-lg-5 text-lg-center">
          <div>
            <h2 className="font-weight-bold mb-0">
              <span
                style={{
                  borderBottom: "2.3px solid #f6e1d5",
                  marginLeft: "10px",
                  color: "var(--accent-dark)",
                }}
              >
                Terms &amp; Conditions
              </span>
            </h2>
          </div>
        </div>
        <div style={{marginBottom:"20px"}}>
        By using our online platforms “www.vedworld.org” (hereinafter VedKendra), you agree:
            <br/><br/>
            <ol>
            <li>That you are a competent user who is eligible to enter into the contract as per the Indian Contract Act.</li>
<li>That you are not specifically forbidden under any law of the land to make such transactions on VedKendra.</li>
<li>That you are not using anyone else’s login credentials or any other data including user name, email-id, password, financial details etc. without prior legal authorization.</li>
<li>That the customer is using secured internet connection and a device which is free from any malware or viruses.</li>
<li>That the customer is not using any illegal or unauthorised VPN server or any other technology which is not permissible to use under the law of the land.</li>
<li>That in case we detect any such activity, omission or use of any such technology; VedKendra has every right and duty to not only cancel the transaction but also take necessary actions against such customer without prior intimation to the customer.</li>
<li>That while surfing the website and placing the order, it is the sole responsibility of the customer to fill in the correct details and place orders. Once the order has been placed and the transaction stands complete, VedKendra shall not be responsible for any wrongly filled information or data. However, the customer can contact the concerned officials of VedKendra through the given channel, nevertheless, it shall be the sole discretion of VedKendra to entertain such requests of the customer.</li>
<li>That it is the sole responsibility of the customer to provide the correct shipping address. In case, the customer provides wrong/insufficient address and the product gets delivered at the address provided by the customer which later turns out to be the wrong address, VedKendra shall not be responsible for any refunds, returns or any other support whatsoever.</li>
<li>That each substance, content, product and pictures utilized on VedKendra is the sole property of VedKendra.</li>
<li>That you shall not post, replicate, download, transfer, transmit, retransmit, circulate, redistribute, produce, republish, recompile, dismantle or make any adjustment or commercial abuse of any exclusive material made accessible through VedKendra.</li>
<li>That VedKendra is not a subsidiary with sites that might be connected to VedKendra through hypertext and dissuades itself from any mishaps happening due to the acts and omissions of such sites.</li>
<li>That VedKendra has no control over and is not in-charge of the substance on any connected site. The connected destinations are for user convenience only and you access at your own risk. </li>
<li>That VedKendra only saves and stores the personal contact details of its customers for analytical purposes or any other purposes as stipulated in the foregoing policies. VedKendra specifically denies of having access to or storing any financial details (bank account number, card details etc.) of its customers.</li>
<li>That the customer shall solely be responsible for any mishaps while operating this website and shall not VedKendra liable or any of its affiliates for any data leak or transactional hazards.</li>
<li>That in case of any dissatisfaction towards the services and/or products of VedKendra, the customer shall first approach to the concerned officer of VedKendra to resolve the issue. We are committed to serve our customers in the best possible way and will try to resolve as soon as possible.</li>
<li>That in case of any dispute, it is only the High Courts located within the territory of Mumbai shall have the appropriate jurisdiction to try and decide the matter. It is further specifically stated that no other Courts of the Republic of India shall have the jurisdiction to try and decide the matter except for the Courts located within the territory of Mumbai.</li>
<li>That you have read, understood and agree to these terms and conditions before moving further and placing order at VedKendra.</li>

           
            </ol>
            
        </div>
            </div>
    </div>
  );
}
