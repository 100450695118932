import React from "react";
import ComingSoon from "./ComingSoon";

export default function vedChakra() {
  return (
    <>
      <ComingSoon />
    </>
  );
}
