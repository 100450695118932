import Axios from 'axios';
import React, { Component } from 'react'
import config from '../../helperFuncs/config';
import baseUrl from "../../helperFuncs/baseUrl";

const styles = {
    borderBottom: "2.3px solid #f6e1d5",
    marginLeft: "10px",
    color: "var(--text-main)",
};

function RenderCart({ cartDetails }) {
    let cartItems = Object.values(cartDetails);
    cartItems.pop();
    const renderedCartItems = cartItems.map((cartItem, index) => {
        const { discountPrice, pID, productName, quantity } = cartItem;
        return (
            <>
                <hr />
                <div>Item {index + 1}</div>
                <div>Amount paid: {discountPrice}</div>
                <div>Product ID: {pID}</div>
                <div>Product Name: {productName}</div>
                <div>Quantity: {quantity}</div>
            </>
        )
    });
    return renderedCartItems;
}

function RenderOrder({ orderId, amountPaid, orderStatus, modeOfPayment, cartDetails }) {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5>Order ID: {orderId}</h5>
                    <div>Amount Paid: {amountPaid}</div>
                    <div>Order Status: {orderStatus}</div>
                    <div>Mode Of Payment: {modeOfPayment}</div>
                    <RenderCart cartDetails={cartDetails} />
                </div>
            </div>
        </>
    )
}

export default class order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: []
        };
        this.getOrders = this.getOrders.bind(this);
    }
    componentDidMount() {
        this.getOrders();
    }
    getOrders() {
        Axios.post(`${Object.values(baseUrl)[0].SERVER_URI}/order/getorderdata`, { uid: localStorage.uid }, config)
            .then(res => {
                console.log(res.data);
                this.setState({
                    orders: [...res.data]
                })
            })
            .catch(err => console.error(err))
    }
    render() {
        console.log(this.state);
        return (
            <>
                <div>
                    <div className="container">
                        <div className="px-lg-4 pb-3">
                            <div>
                                <div className="text-left pt-2 py-lg-5">
                                    <div>
                                        <h2 className="mb-0">
                                            <span
                                                style={styles}
                                            >
                                                Your Orders
                  </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {this.state.orders.length > 0 ? (
                                    <>
                                        {this.state.orders.map((order, index) => {
                                            const { orderId, cartDetails, amountPaid, date, modeOfPayment, orderStatus } = order;
                                            return (<RenderOrder
                                                orderId={orderId}
                                                cartDetails={cartDetails}
                                                amountPaid={amountPaid}
                                                date={date}
                                                modeOfPayment={modeOfPayment}
                                                orderStatus={orderStatus}
                                                key={orderId + index}
                                            />)
                                        }
                                        )}
                                    </>
                                ) : (<div />)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
